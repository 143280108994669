import React from "react";
import styles from "./About.module.css";

const About = () => (
  <div className={styles.about}>
    <a className={styles.mainLink} href="https://electronicmusic.love">
      <div>Electronic Music</div>
    </a>
    <div className={styles.description}>
      Curated list of electronic music radio stations
    </div>
  </div>
);

export default React.memo(About);
